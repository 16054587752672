.sidebar-container {
  &.ant-layout-sider {
    width: $sidebarContainerWidth !important;
    flex: unset !important;
    max-width: unset !important;
    background: var(--bgColorMain);
    // padding: $px15 0 $px15 $px15;
    // padding: 0 $px8;
    outline: none;
    min-width: unset !important;
  }

  .ant-layout-sider-children {
    background: var(--sidebarContentBg);
    border-radius: $px15;
    .sidebar-box {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .sidebar-content {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100vh;
        .collapse-icon {
          position: absolute;
          right: $px16;
          top: $px20;
          line-height: 0 !important;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          svg {
            width: $px18;
            height: auto;
          }
        }
        .logo {
          height: $px60;
          display: flex;
          justify-content: flex-start;
          padding: $px20 0 $px20 $px32;
          font-size: $px24;
          font-weight: 700;
          line-height: $px20;
          background-color: #ffffff;
        }

        .ant-menu {
          flex: 1;
          overflow-y: auto;
          background: var(--sidebarContentBg);
          padding: 0 $px10 !important;
          color: #374151;
          outline: none;
          &.ant-menu-root {
            > .ant-menu-item {
              padding-left: $px25 !important;
              outline: none;
              margin-inline: $px4;
              margin-block: $px4;
              padding-inline: $px16;
            }
          }
          svg {
            width: $px20;
            height: $px20;
            path {
              fill: #374151;
            }
          }

          //highlight when submenu selected
          .ant-menu-submenu-selected {
            color: #2563eb;
            .ant-menu-submenu-title svg {
              path {
                fill: #2563eb;
              }
            }
          }

          .ant-menu-item:not(.ant-menu-item-selected):active {
            background: transparent;
          }
          .ant-menu-submenu-title:active {
            background: #ffffff !important;
            color: #2563eb;
          }

          .ant-menu-title-content {
            font-size: $px18;
            line-height: $px28;
            font-weight: 500;
            margin-inline-start: $px10;
          }

          .ant-menu-item {
            width: 100%;
            margin: 0;
            height: $px60;
            border-radius: 0;
            // border-top-left-radius: calc(#{$px45}*0.5);
            // border-bottom-left-radius: calc(#{$px45}*0.5);
            border-radius: calc(#{$px45}* 0.5);
            position: relative;
            overflow: initial;
            margin: $px4 0;
            transition: unset;
            &:hover {
              background: #ffffff !important;
              color: #2563eb;
              a {
                color: #2563eb;
              }
              &:before {
                background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.83 0C11.8247 2.98022 10.637 5.83656 8.52783 7.94202C6.41862 10.0475 3.56018 11.23 0.579956 11.23H11.83V0Z' fill='#eff6ff'");
              }
              &:after {
                background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.83 11.45C11.8247 8.47069 10.6367 5.61537 8.52722 3.51149C6.41772 1.40762 3.55926 0.22733 0.579956 0.229985H11.83V11.45Z' fill='#eff6ff'");
              }
              svg {
                path {
                  fill: #2563eb;
                }
              }
            }

            .ant-menu-item-icon {
              min-width: $px24;
              font-size: $px24;
            }

            &:focus,
            &:visited,
            &:active,
            &.ant-menu-item-selected {
              z-index: 9;
              background: #ffffff !important;
              color: #2563eb;
              &:before {
                background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.83 0C11.8247 2.98022 10.637 5.83656 8.52783 7.94202C6.41862 10.0475 3.56018 11.23 0.579956 11.23H11.83V0Z' fill='#eff6ff'");
              }
              &:after {
                background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.83 11.45C11.8247 8.47069 10.6367 5.61537 8.52722 3.51149C6.41772 1.40762 3.55926 0.22733 0.579956 0.229985H11.83V11.45Z' fill='#eff6ff'");
              }
              svg {
                path {
                  fill: #2563eb;
                }
              }
            }
          }

          .ant-menu-submenu {
            .ant-menu-item {
              padding-left: $px43 !important;
            }
            .ant-menu-submenu-title {
              width: 100%;
              height: $px45;
              padding-left: $px15 !important;
              border-radius: 0;
              border-top-left-radius: calc(#{$px45}* 0.5);
              border-bottom-left-radius: calc(#{$px45}* 0.5);
              margin: $px4;
              margin-left: 0;
              position: relative;
              overflow: initial;
              transition: unset;
              &:before {
                content: "";
                position: absolute;
                top: -$px10;
                right: 0;
                display: block;
                width: $px10;
                height: $px10;
                transform: unset !important;
                opacity: unset !important;
              }
              &:after {
                content: "";
                position: absolute;
                top: calc(100% - #{$px1});
                right: 0;
                display: block;
                width: $px10;
                height: $px10;
                transform: unset !important;
                opacity: unset !important;
              }
              &:hover {
                background: #ffffff !important;
                color: #2563eb;
                &:before {
                  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.83 0C11.8247 2.98022 10.637 5.83656 8.52783 7.94202C6.41862 10.0475 3.56018 11.23 0.579956 11.23H11.83V0Z' fill='#eff6ff'");
                }
                &:after {
                  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.83 11.45C11.8247 8.47069 10.6367 5.61537 8.52722 3.51149C6.41772 1.40762 3.55926 0.22733 0.579956 0.229985H11.83V11.45Z' fill='#eff6ff'");
                }
                svg {
                  path {
                    fill: #2563eb;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  //  collapse //
  &.ant-layout-sider-collapsed {
    width: $sidebarContainerWidthCollapse !important;
    .ant-layout-sider-children {
      .sidebar-box {
        .sidebar-content {
          .logo {
            display: none !important;
          }
          .ant-menu {
            //padding-left: 0!important;
            //padding-top: $px10;
            margin: $px22 0 !important;
            padding: 0 $px10 !important;
            .ant-menu-submenu {
              .ant-menu-submenu-title {
                width: 100%;
                border-radius: $px10 !important;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
                padding: 0 !important;
                .ant-menu-title-content {
                  display: none;
                }
                &:before {
                  display: none;
                }
                &:after {
                  display: none;
                }
              }
            }
            .ant-menu-item {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              //border-radius: 0!important;
              border-radius: $px10 !important;
              margin: $px4 0;
              padding: 0 !important;
              .ant-menu-title-content {
                display: none;
              }
              &:before {
                display: none;
              }
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.ant-menu.ant-menu-sub.ant-menu-inline {
  margin-top: -$px4 !important;
}

.ant-menu.ant-menu-sub.ant-menu-vertical {
  min-width: $px160;
  max-height: calc(100vh - #{$px100});
  border-radius: $px8;
  .ant-menu-item {
    height: $px40;
    line-height: $px40;
    padding-inline: $px16;
    margin-inline: $px4;
    margin-block: $px4;
    width: calc(100% - #{$px8});
    border-radius: $px4;
    font-size: $px16;
    display: flex;
    align-items: center;
    .ant-menu-title-content {
      margin-inline-start: $px10;
    }
    &:hover,
    &.ant-menu-item-selected {
      background: #ffffff !important;
      color: #2563eb;
      svg path {
        fill: #2563eb;
      }
    }
  }
}
.sidebar-icon.dot {
  width: $px8 !important;
  min-width: $px8 !important;
  height: $px8 !important;
}

.un-collapse-icon {
  cursor: pointer;
  text-align: center;
  font-size: $px20;
  color: var(--sidebarContentTextColor1);
  padding-bottom: $px16;
  transform: rotate(180deg);
  svg {
    width: $px18;
    height: auto;
  }
}
