// base on vw = 1920px
$px1: 0.052vw;
$px2: 0.104vw;
$px3: 0.156vw;
$px4: 0.208vw;
$px5: 0.26vw;
$px6: 0.313vw;
$px7: 0.365vw;
$px8: 0.417vw;
$px9: 0.469vw;
$px10: 0.521vw;
$px11: 0.573vw;
$px12: 0.625vw;
$px13: 0.677vw;
$px14: 0.729vw;
$px15: 0.781vw;
$px16: 0.833vw;
$px17: 0.885vw;
$px18: 0.938vw;
$px20: 1.042vw;
$px22: 1.146vw;
$px24: 1.25vw;
$px25: 1.302vw;
$px26: 1.354vw;
$px28: 1.458vw;
$px30: 1.563vw;
$px32: 1.667vw;
$px34: 1.771vw;
$px35: 1.823vw;
$px36: 1.875vw;
$px37: 1.927vw;
$px40: 2.083vw;
$px42: 2.188vw;
$px43: 2.24vw;
$px44: 2.292vw;
$px45: 2.344vw;
$px48: 2.5vw;
$px50: 2.604vw;
$px52: 2.708vw;
$px55: 2.865vw;
$px58: 3.021vw;
$px60: 3.125vw;
$px64: 3.333vw;
$px65: 3.385vw;
$px68: 3.542vw;
$px70: 3.646vw;
$px72: 3.75vw;
$px75: 3.906vw;
$px76: 3.958vw;
$px78: 4.063vw;
$px80: 4.167vw;
$px82: 4.271vw;
$px85: 4.427vw;
$px88: 4.583vw;
$px90: 4.688vw;
$px92: 4.792vw;
$px100: 5.208vw;
$px110: 5.729vw;
$px125: 6.51vw;
$px130: 6.771vw;
$px142: 7.396vw;
$px150: 7.813vw;
$px160: 8.333vw;
$px175: 9.115vw;
$px180: 9.375vw;
$px200: 10.417vw;
$px240: 12.5vw;
$px252: 13.125vw;
$px260: 13.542vw;
$px265: 13.802vw;
$px275: 14.323vw;
$px290: 15.104vw;
$px300: 15.625vw;
$px350: 18.229vw;
$px400: 20.833vw;
$px425: 22.135vw;
$px430: 22.396vw;
$px450: 23.438vw;
$px520: 27.083vw;
$px912: 47.5vw;
$px1114: 58.021vw;
$px1250: 65.104vw;
$px1338: 69.688vw;

$sidebarContainerWidth: 18.75vw;
$sidebarContainerWidthCollapse: 4.688vw;

// $dashboardOrderListHeight: ($px30+$px24)*7 - $px24;
// $dashboardTodoListMaxHeight: 26.042vw;
