.box-wrapper {
  padding: 0 $px16 $px16;
  .form-title {
    text-align: center;
    margin-bottom: $px12;
    font-size: $px24;
    font-weight: bold;
    color: var(--commonInputColor);
  }

  .form-btn-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: $px8 0;
  }
}

.journey-box {
  padding: $px20 20% !important;
  .violate-list-icon {
    margin: $px8 0;
    display: flex;
    flex: 1;
    div {
      height: $px32;
      width: $px52;
      justify-content: center;
      align-items: center;
      display: flex;
      margin-right: $px8;
      background-color: #bbbbbd;
      border-radius: $px4;
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
    }
    svg {
      height: 80%;
      width: auto;
      fill: #303845;
    }
  }
}

.journey-info-detail {
  display: flex;
  .info-left {
    width: 50%;
    padding: $px8 $px16 $px8 $px8;
    border-right: $px1 solid rgba(0, 0, 0, 0.5);
  }

  .info-right {
    width: 50%;
    padding: $px8 $px8 $px8 $px20;
  }
}

.journey-history {
  width: 100%;
  padding: 0 $px60;
  .journey-list-icons {
    width: 100%;
    height: $px28;
    display: flex;
    margin-bottom: $px12;
    .jounrney-icon {
      cursor: pointer;
      height: 100%;
      width: $px28;
      margin-right: $px12;
      display: flex;
      justify-self: center;
      align-items: center;
      svg path {
        fill: #374151;
        height: 100%;
        width: auto;
        &:hover {
          opacity: 0.8;
        }
      }

      &.disabled-icon {
        cursor: not-allowed !important;
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }
  .map-wrapper {
    height: $px520;
    width: 100%;
  }

  .btn-export {
    margin: $px20 0 $px12;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .journey-history-detail {
    display: flex;
    .info-left {
      width: 30%;
      padding: $px8 $px16 $px8 0;
      border-right: $px1 solid rgba(0, 0, 0, 0.5);
    }

    .info-right {
      width: 70%;
      padding: $px8 0 $px8 $px20;
    }
  }
}
