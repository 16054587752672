.noti-container {
  .ant-badge-count {
    top: $px5;
    right: $px5;
  }
  svg {
    width: $px30;
    height: $px30;
    path {
      fill: var(--sidebarContentTextColor1);
    }
  }
}

.header-avatar {
  width: $px50;
  height: $px50;
  line-height: $px50;
}
.header-language {
  width: $px30;
  height: $px30;
  line-height: $px30;
}

.header-language-dropdown-item {
  width: $px20;
  height: $px20;
  line-height: $px20;
  margin-right: $px10;
}

.page-sidebar-container {
  width: 100%;
  height: 100%;
  padding: $px8;
}
//
.ant-tabs .ant-tabs-content {
  height: 100%;
  .ant-tabs-tabpane {
    height: 100%;
  }
}

.ant-tabs-nav {
  margin: 0 0 $px16 0 !important;
  &::before {
    border-bottom: $px1 solid rgba(5, 5, 5, 0.06) !important;
  }
}

.ant-tabs-tab {
  font-size: $px16 !important;
  font-weight: 500 !important;
  padding: $px12 0 !important;
  & + .ant-tabs-tab {
    margin: 0 0 0 $px32 !important;
  }
  .ant-tabs-tab-btn:focus-visible,
  .ant-tabs-tab-btn {
    outline: none;
  }
}

.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  height: $px2 !important;
}
//

img[alt="Marker"] {
  display: none;
}

.ant-space {
  gap: $px20 !important;
}

.ant-avatar {
  border: $px1 solid transparent !important;
  font-size: $px16 !important;
}
