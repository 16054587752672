.login-container {
  display: flex;
  width: 100%;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  background-size: cover;
  overflow: hidden;

  .login-box {
    transform: scale(1.3);
    background: linear-gradient(
      141.2deg,
      rgba(217, 217, 217, 0.3) -4.06%,
      rgba(217, 217, 217, 0) 52.3%,
      rgba(217, 217, 217, 0.3) 100%
    );
    border-radius: $px20;
    padding: $px20 $px40;
    width: $px520;

    .style-size-logo {
      width: $px350;
      max-width: 100%;
      margin: -$px50 0;
      height: auto;
    }

    .txt-title {
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      font-size: $px24;
      margin-bottom: $px24;
    }

    .txt-welcome {
      color: #fff;
      text-align: center;
      font-size: $px24;
      margin-bottom: $px24;
    }

    .avic-form-item {
      margin-bottom: $px20;
    }

    .ant-input-affix-wrapper-lg {
      padding: $px7 $px11;
      font-size: $px16;
    }
    .ant-form-item-control-input {
      min-height: $px40;
    }
    .ant-input-affix-wrapper {
      background: rgba(217, 217, 217, 0.2);
      border: $px1 solid #bdbdbd;
      border-radius: $px5;
      height: $px45;
      padding-left: $px20;
      .ant-input {
        background: transparent;
        color: #fff;
        font-size: $px18;
        &::placeholder {
          color: #a0a0a0;
        }
        &:-webkit-autofill:focus,
        &:-webkit-autofill {
          transition: background-color 600000s 0s, color 600000s 0s;
        }
      }
      .ant-input-suffix {
        svg {
          color: #fff;
        }
      }
    }

    .ant-input-outlined.ant-input-status-error {
      background-color: rgba(217, 217, 217, 0.2) !important;
    }

    .ant-checkbox {
      border-radius: $px4;
      & + span {
        padding-inline-start: $px8;
        padding-inline-end: $px8;
        color: white !important;
      }
    }

    .language_select {
      display: flex;
      justify-content: center;
      margin: $px24 0 $px16;
      .ant-select {
        font-size: $px16;
        height: $px40;
        .ant-select-selector {
          background: rgba(217, 217, 217, 0.2);
          border: $px2 solid #bdbdbd;
          border-radius: $px5;
          padding: 0 $px40;
          min-width: $px240;
          .ant-select-selection-item {
            color: #fff;
            padding-inline-end: $px18;
            text-align: center;
          }
          .ant-select-selection-search-input {
            height: $px40;
          }
        }
        .ant-select-arrow {
          svg {
            color: #fff;
          }
        }
      }
    }

    .ant-checkbox-wrapper {
      color: #fff;
      font-size: $px20;
      outline: none !important;
      .ant-checkbox-input {
        width: $px14;
        height: $px14;
        box-shadow: 0 0 0 $px2 rgba(5, 145, 255, 0.1);
      }
    }

    .btn-login {
      border-radius: $px22 !important;
      width: 100% !important;
      background: #f2994a !important;
      height: $px58 !important;
      font-size: $px20 !important;
      color: #fff !important;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s !important;
    background-color: transparent !important;
  }
  input[data-autocompleted] {
    background-color: transparent !important;
  }
}

.ant-modal.modal-change-password {
  .ant-modal-title {
    text-align: center;
  }
  .ant-modal-footer {
    justify-content: center;
  }

  .avic-form {
    .ant-form-item-label {
      width: $px275;
      text-align: left;
    }
    .password-error {
      color: #f43f5e;
      margin-top: $px4;
      font-size: $px16;
    }
    .password-condition-active {
      color: #0d9488;
      svg path {
        fill: #0d9488;
      }
    }
    .password-condition-error {
      color: #e11d48;
      svg path {
        fill: #e11d48;
      }
    }
    .form-btn-container {
      padding-top: $px40;
      display: flex;
      justify-content: center;
    }
    &#change-password {
      .ant-form-item {
        margin-bottom: $px12;
        .ant-input-clear-icon {
          margin-inline-end: $px8;
          margin: 0 $px8;
          font-size: $px12;
          vertical-align: -$px1;
        }
      }
      .ant-form-item-label {
        padding: 0;
        > label {
          color: var(--commonInputColor);
          font-size: $px16;
          font-weight: 400;
          height: $px32;
        }
      }
      .ant-form-item-explain-error {
        font-size: $px16;
      }
      .ant-form-item-control-input {
        min-height: $px35;
        height: $px35;
      }
      .ant-input-password {
        height: $px35;
        background: var(--commonInputBg);
        color: var(--commonInputColor);
        font-size: $px14;
        padding: $px4 $px12;
        border-radius: $px12;
        border-width: $px1;
        font-weight: 400;
      }
      .change-password-condition {
        .change-password-condition-title {
          font-size: $px16;
          font-weight: 500;
        }
        .ant-list-item {
          font-size: $px16;
          padding: $px12 $px24;
          svg {
            margin-right: $px8;
            width: $px18;
            height: $px18;
          }
        }
        input {
          font-size: $px16;
        }
        .avic-btn.ant-btn {
          min-width: $px90;
          width: $px90;
        }
      }
    }
  }
}
