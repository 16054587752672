.location-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .location-list {
    width: 50%;
    padding: 0 0 0 $px16 !important;
    .advance-search-box {
      height: 100%;
      overflow-y: auto;
    }
  }
}

.location-history-box {
  display: flex;
  .info-left {
    width: 25%;
    padding: $px32 $px16 $px8 $px8;
    border-right: $px1 solid rgba(0, 0, 0, 0.5);
  }

  .info-right {
    width: 75%;
    padding: $px8 $px8 $px8 $px20;
  }
}
