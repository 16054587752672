.map-wrapper {
  width: 50%;
  height: 100%;
  border: $px1 solid rgba(0, 0, 0, 0.1);
  .leaflet-container {
    height: 100%;
    .leaflet-top.leaflet-left,
    .leaflet-bottom.leaflet-right {
      display: none;
    }
    .leaflet-control .ant-btn {
      width: $px34;
      height: $px34;
      padding: 0;
      border: $px2 solid rgba(0, 0, 0, 0.2);
      border-radius: $px6;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: $px18;
        height: $px18;
        path {
          fill: #000000;
        }
      }
    }
  }
  .leaflet-touch .leaflet-bar a {
    width: $px30;
    height: $px30;
    line-height: $px30;
    font-size: $px22;
    border-bottom: $px1 solid #ccc;
    display: flex;
    justify-content: center;
    &:first-child {
      border-top-left-radius: $px2;
      border-top-right-radius: $px2;
    }
  }

  .leaflet-touch .leaflet-control-geocoder {
    min-width: 0;
    min-height: 0;
    line-height: 0;
  }

  .leaflet-touch .leaflet-control-geocoder-icon {
    width: $px30;
    height: $px30;
    padding: 0;
    border-radius: $px6;
  }

  .leaflet-control-geocoder-form {
    line-height: $px30;
    font-size: $px14;
  }

  .leaflet-control-geocoder-alternatives {
    // padding-inline-start: $px40;
    li a {
      display: unset !important;
      &:hover {
        line-height: normal;
      }
    }
    li a span {
      line-height: 0;
      font-size: $px14;
    }
  }
  .leaflet-top .leaflet-control {
    margin-top: $px10;
  }
  .leaflet-right .leaflet-control {
    margin-right: $px10;
  }

  .leaflet-touch .leaflet-bar {
    border: $px2 solid rgba(0, 0, 0, 0.2);
    border-radius: $px6;
  }

  .leaflet-control-layers.leaflet-control {
    width: $px34;
    height: $px34;
    border: $px2 solid rgba(0, 0, 0, 0.2);
    border-radius: $px4;

    .leaflet-control-layers-toggle {
      width: $px30;
      height: $px30;
      background-size: $px24 $px24;
    }
    &.leaflet-control-layers-expanded {
      width: $px160;
      height: $px60;
      border: $px2 solid rgba(0, 0, 0, 0.2);
      border-radius: $px4;
      padding: $px6 $px10 $px6 $px6;
      .leaflet-control-layers-overlays {
        label {
          line-height: $px20;
          span {
            font-size: $px14 !important;
          }
          input.leaflet-control-layers-selector {
            width: $px14;
            height: $px14;
            margin-top: $px2;
            top: $px1;
          }
          & + label {
            margin-top: $px4;
          }
        }
      }
      .leaflet-control-layers-base {
        label {
          line-height: $px20;
          font-size: $px14 !important;
          input.leaflet-control-layers-selector {
            margin-top: -$px4;
            position: relative;
            top: $px1;
            padding-block: $px1;
            padding-inline: $px2;
            width: $px14;
            height: $px14;
            margin-right: $px4;
          }

          & > span {
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
          & + label {
            margin-top: $px4;
          }
        }
      }
    }
  }

  .leaflet-popup-content {
    margin: $px16 $px28 $px16 $px24;
    min-height: $px1;
  }
}
