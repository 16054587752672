.lock-info-detail {
  padding: $px16 20%;
  display: flex;

  .lock-title {
    font-size: $px18;
    font-weight: 500;
    margin-bottom: $px12;
    text-align: center;
  }
  .info-left {
    width: 50%;
    padding-right: $px24;
  }
  .info-right {
    padding-left: $px24;
    width: 50%;
  }
}

.lock-info-tab {
  display: flex;
  .info-left {
    width: 25%;
    padding: $px32 $px16 $px8 $px8;
    border-right: $px1 solid rgba(0, 0, 0, 0.5);
  }

  .info-right {
    width: 75%;
    padding: $px8 $px8 $px8 $px20;
  }
}

.decentralize-key-usage-modal {
  width: 60% !important;
}
