.group-box {
  padding: $px20 20% !important;
}

.group-line {
  margin-top: $px20;
  position: relative;
  &::after {
    content: "";
    display: block;
    width: $px1;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    border-right: $px1 dashed rgba(0, 0, 0, 0.5);
  }

  .group-wrapper {
    padding: 0 8%;
    .group-item {
      padding: $px16 $px24;
      border-radius: $px8;
      border: $px1 solid rgba(0, 0, 0, 0.5);
      .group-sub-title {
        padding: $px8 0 $px24;
        color: var(--commonInputColor);
        font-size: $px18;
        font-weight: 500;
        border-bottom: $px1 dashed rgba(0, 0, 0, 0.5);
        margin-bottom: $px24;
      }
    }
  }
}
