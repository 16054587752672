.profile-page {
  //box-shadow: 0 0 $px24 rgba(0, 0, 0, 0.1);
  //border-radius: $px7;
  margin-top: $px4;
  padding: 0 $px16 0 $px8;
  .profile-page-sidebar {
    box-shadow: 0 0 $px10 rgba(0, 0, 0, 0.1);
    padding-top: $px20;
    border-radius: $px7 $px7 0 0;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    //align-items: center;

    .profile-info {
      color: #334d6e;

      .profile-info-title {
        font-size: $px26;
        line-height: $px30;
        font-weight: 500;
        margin-bottom: $px5;
      }
      .profile-info-full-name {
        font-size: $px26;
        line-height: $px30;
        font-weight: 600;
        margin-bottom: $px5;
        text-align: center;
      }
      .profile-info-role {
        font-size: $px16;
        line-height: $px28;
        font-weight: 400;
        text-align: center;
        padding-bottom: $px30;
      }
      .profile-info-row {
        font-size: $px16;
        line-height: $px28;
        font-weight: 500;
        border: $px1 solid #f1f1f1;
        padding: $px18 $px24;
        .profile-info-account-label {
          width: $px110;
          display: inline-block;
          font-weight: 400;
          color: var(--commonInputColor);
        }
      }
    }
  }

  .profile-body {
    padding: $px20 $px50;
    box-shadow: 0 0 $px10 rgba(0, 0, 0, 0.1);
    border-radius: $px7;
    background: #fff;

    .ant-form-item-label {
      padding: 0 0 $px8;
      line-height: 0;
    }

    .profile-tab-content {
      padding-top: $px20;
      .profile-tab-content-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $px20;
        line-height: 0;

        .profile-tab-content-header-left {
          font-size: $px18;
          font-weight: 600;
        }
        .profile-tab-content-header-right {
          //svg {
          //  width: $px24;
          //}
          .anticon {
            font-size: $px24;
          }
        }
      }

      .avic-form {
        .ant-form-item-label {
          width: $px275;
          text-align: left;
        }
        .password-error {
          color: #f43f5e;
          margin-top: $px4;
          font-size: $px16;
        }
        .password-condition-active {
          color: #0d9488;
          svg path {
            fill: #0d9488;
          }
        }
        .password-condition-error {
          color: #e11d48;
          svg path {
            fill: #e11d48;
          }
        }
        .form-btn-container {
          padding-top: $px40;
          display: flex;
          justify-content: center;
        }
        &#change-password {
          .ant-form-item {
            margin-bottom: $px12;
            .ant-input-clear-icon {
              margin-inline-end: $px8;
              margin: 0 $px8;
              font-size: $px12;
              vertical-align: -$px1;
            }
          }
          .ant-form-item-label {
            padding: 0;
            > label {
              color: var(--commonInputColor);
              font-size: $px16;
              font-weight: 400;
              height: $px32;
            }
          }
          .ant-form-item-explain-error {
            font-size: $px16;
          }
          .ant-form-item-control-input {
            min-height: $px35;
            height: $px35;
          }
          .ant-input-password {
            height: $px35;
            background: var(--commonInputBg);
            color: var(--commonInputColor);
            font-size: $px12;
            padding: $px4 $px12;
            border-radius: $px12;
            border-width: $px1;
            font-weight: 400;
          }
          .change-password-condition {
            .change-password-condition-title {
              font-size: $px16;
              font-weight: 500;
            }
            .ant-list-item {
              font-size: $px16;
              padding: $px12 $px24;
              svg {
                margin-right: $px8;
                width: $px18;
                height: $px18;
              }
            }
            input {
              font-size: $px16;
            }
            .avic-btn.ant-btn {
              min-width: $px90;
              width: $px90;
            }
          }
        }
      }
    }
  }
}
