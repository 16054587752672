.vendor-box {
  padding: $px20 20% 0 !important;
}

.vendor-info-tab {
  display: flex;
  .info-left {
    width: 25%;
    padding: $px32 $px16 $px8 $px8;
    border-right: $px1 solid rgba(0, 0, 0, 0.5);
  }

  .info-right {
    width: 75%;
    padding: $px8 $px8 $px8 $px20;
  }
}

.company-edit-modal {
  min-width: 50% !important;
}

.info-mark {
  width: $px20;
  height: $px20;
  margin-left: $px4;
  cursor: pointer;
}
