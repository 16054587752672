.operate-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;

  .list-operate {
    width: 50%;
    height: 100%;
    padding-right: $px16;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: $px48;
    .list-item {
      max-height: 100%;
      overflow-y: auto;
      margin-left: -$px10;
      margin-right: -$px10;
      row-gap: $px20;
      .ant-col.ant-col-12 {
        padding-left: $px10;
        padding-right: $px10;
      }
      .operate-item {
        overflow: hidden;
        position: relative;
        padding: $px28 $px24 $px16;
        background: #ffffff;
        border: $px1 solid rgba(38, 38, 38, 0.3);
        border-radius: $px8;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        min-height: $px252;
        .item-content {
          width: 100%;
          padding-left: $px16;
          margin-bottom: $px12;
          .ant-row {
            margin-bottom: $px8;
          }
          .ant-col {
            font-size: $px16;
            &.label {
              font-weight: 600;
            }
          }
        }

        .item-index {
          position: absolute;
          padding: $px4 $px8;
          top: -$px2;
          left: 0;
          border-top-left-radius: $px8;
          border-bottom-right-radius: $px8;
          font-size: $px14;
          font-weight: bold;
          border: $px1 solid rgba(38, 38, 38, 0.3);
          border-left: 0 !important;
        }

        .ant-btn {
          height: $px36;
          padding: 0;
        }
      }
    }

    .operate-pagination {
      margin-top: $px4;
      text-align: right;
      position: absolute;
      bottom: -$px4;
      right: $px16;
      .avic-pagination {
        text-align: right;
      }
      .ant-pagination-total-text {
        font-weight: 400;
        font-size: $px16;
        line-height: $px30;
        color: var(--commonInputColor);
        height: $px32;
        margin-top: $px16;
        margin-inline-end: $px16;
      }
    }
  }
}
