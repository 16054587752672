/// start css search box
.avic-search-box {
  margin-bottom: $px20;
  &.showApproveBtn {
    margin-top: $px4;
    .avic-search-box-accept {
      position: absolute;
      top: $px82;
      right: $px15;
      z-index: 100;

      .ant-btn {
        svg {
          height: $px16;
          width: $px16;
          path {
            fill: #ffffff;
          }
        }
        &.default svg path {
          fill: var(--btnDefaultBorder);
        }
      }
    }
  }

  .normal-search-box {
    .avic-input {
      height: $px45;
      padding: 0;
    }
    .ant-input-group-addon {
      padding: 0;
      .btn-normal-search {
        background: #fff;
        margin-top: -$px1;
        width: $px45;
        height: calc(#{$px45} - #{$px3});
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-right-radius: $px8;
        border-bottom-right-radius: $px8;
        svg {
          path {
            fill: var(--commonInputColor);
          }
        }
      }
    }
    .ant-input-affix-wrapper {
      height: $px45;
      font-size: $px16;
      border-radius: $px6;
      background: var(--commonInputBg);
      border-top-left-radius: $px8;
      border-bottom-left-radius: $px8;
      border-width: $px1;
      .ant-input {
        border-left: $px1 solid #cfd9e0;
        padding-left: $px14;
        background: transparent !important;
        color: var(--commonInputColor);
        font-size: $px16;
        font-weight: 400;
        &::placeholder {
          color: var(--commonInputPlaceholder);
          font-size: $px16;
          font-weight: 400;
        }
      }

      .open-advance-search-btn {
        cursor: pointer;
        padding-right: $px10;
        padding-left: $px10;
        color: var(--commonInputColor);
        font-size: $px16;
        font-weight: 400;
        display: flex;

        .anticon {
          padding-left: $px10;
        }
      }
    }
  }

  .advance-search-box {
    padding: $px20;
    background: #fff;
    border: $px1 solid #cfd9e0;
    box-shadow: 0 0 $px24 rgba(0, 0, 0, 0.1);
    border-radius: $px7;
    position: relative;
    font-size: $px16;

    .close-advance-search-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      //cursor: pointer;
      margin-bottom: $px14;
      > div {
        font-size: $px18;
        font-weight: 500;
        color: var(--commonInputColor);
      }

      .advance-search-action {
        position: absolute;
        right: 0;
        top: 0;
        border-top-right-radius: $px7;
        height: $px55;
        .ant-space-item {
          height: 100%;
        }
        .advance-search-action-item {
          display: flex;
          align-items: center;
          justify-items: center;
          padding: 0 $px20;
          height: 100%;

          svg {
            width: $px16;
            height: $px16;
            margin-right: $px8;
            path {
              fill: #828282;
            }
          }

          &:hover {
            color: var(--btnPrimaryBg);
            background-color: rgba(131, 56, 236, 0.1);
            svg path {
              fill: var(--btnPrimaryBg);
            }
          }
        }
        &::after {
          content: "";
          display: block;
          height: $px1;
          width: 83.5vw;
          background-color: #d5d5d5;
          bottom: 0;
          left: -65vw;
          position: absolute;
        }
      }
    }

    .search-label {
      font-size: $px16;
      font-weight: 400;
      margin-bottom: $px4;
      height: $px20;
      color: var(--commonInputPlaceholder);
    }

    .form-btn-container {
      display: flex;
      margin-top: $px8;
      justify-content: flex-end;
    }
  }
}
/// end css search box

// start css common
.avic-form {
  label {
    font-size: $px16;
  }
  .avic-form-item {
    margin-bottom: $px12;
    line-height: 0;
    .css-label {
      font-size: $px16;
      font-weight: 400;
    }

    .ant-form-item-label {
      padding: 0 0 $px8;
      line-height: 0;
      label {
        color: var(--commonInputColor);
        font-size: $px16;
        font-weight: 400;
        height: $px32;
        .required-mark {
          margin-left: $px2;
          color: #ff4d4f;
        }
      }
    }

    .ant-form-item-label label,
    .ant-radio-wrapper,
    .ant-form-item-explain-error {
      font-size: $px16;
      font-weight: 400;
    }

    .ant-radio-wrapper {
      color: var(--commonInputColor);
      margin-inline-end: $px8;
      font-size: $px16;
      .ant-radio-inner {
        width: $px16;
        height: $px16;
        border-width: $px1;
        &::after {
          width: $px16;
          height: $px16;
          margin-block-start: -$px8;
          margin-inline-start: -$px8;
          border-radius: $px16;
        }
      }
    }

    .ant-switch {
      min-width: $px44;
      height: $px22;
      margin-right: $px8;

      .ant-switch-handle {
        top: $px2;
        width: $px18;
        height: $px18;
        inset-inline-start: $px2;
        &::before {
          border-radius: $px9;
        }
      }

      &.ant-switch-checked > .ant-switch-handle {
        inset-inline-start: calc(100% - #{$px20});
      }
    }

    &.is-view {
      .required-mark {
        display: none;
      }
    }

    .ant-form-item-required {
      &:before {
        display: none !important;
      }
    }

    .ant-input-clear-icon {
      margin-inline-end: $px8;
      margin: 0 $px8;
      font-size: $px16;
      vertical-align: -$px1;
      line-height: 0;
    }
  }

  &.ant-form-vertical {
    .ant-form-item-label {
      padding: 0 0 $px8;
      label {
        height: $px20;
      }
    }
  }

  .ant-input-status-error {
    border-width: $px1 !important;
  }

  .ant-form-item-control-input {
    min-height: $px35;
    // height: $px35;
  }
  .ant-input-password {
    height: $px35;
    background: var(--commonInputBg);
    color: var(--commonInputColor);
    font-size: $px16;
    padding: $px4 $px12;
    border-radius: $px12;
    border-width: $px1;
    font-weight: 400;
    &.ant-input-affix-wrapper-disabled {
      background: var(--commonInputDisable);
      color: var(--commonInputPlaceholder);
    }
  }
}
// end css common

// start css input
.avic-select-view,
.avic-input-view {
  font-size: $px16;
}

.avic-input {
  height: $px35;
  background: var(--commonInputBg);
  color: var(--commonInputColor);
  font-size: $px16;
  padding: $px4 $px12;
  border-radius: $px12;
  border-width: $px1;
  font-weight: 400;
  font-family: "SF Pro Display", sans-serif;
  input::placeholder {
    color: var(--commonInputPlaceholder) !important;
    font-size: $px16 !important;
    font-weight: 400 !important;
  }
  &[disabled],
  &.ant-input-disabled,
  &.ant-input-disabled:hover {
    background: var(--commonInputDisable) !important;
    color: var(--commonInputColor) !important;
  }
  .ant-input-group {
    font-size: $px16;
    .ant-input-group-addon {
      background: #fff;
      font-size: $px16;
      border: $px1 solid #d9d9d9;
      // width: $px1;
      padding: 0 $px11;
      border-radius: $px6;
      svg {
        height: $px20;
        width: $px20;
      }
    }
  }
  .ant-input-affix-wrapper {
    //font-size: $px16;
    padding: $px4 $px12;
  }
  &.ant-input-number {
    padding: 0;
  }

  .ant-input-suffix {
    margin-inline-start: $px4 !important;
  }
  .ant-input-prefix {
    margin-inline-end: $px4 !important;
    svg {
      width: $px20;
      height: $px20;
    }
  }
}

.ant-input-outlined:focus-within,
.ant-input-outlined:focus {
  box-shadow: 0 0 0 $px2 rgba(5, 145, 255, 0.1);
}

.ant-input-textarea {
  padding: 0 !important;
  textarea {
    height: $px35 !important;
    background: var(--commonInputBg);
    background-color: red !important;
    color: var(--commonInputColor);
    font-size: $px16;
    padding: $px4 $px12;
    border-radius: $px12;
    // border-bottom-right-radius: 0;
    font-weight: 400;
    font-family: "SF Pro Display", sans-serif;
    min-height: $px35 !important;
    max-height: none !important;
    &::placeholder {
      color: var(--commonInputPlaceholder);
      font-size: $px16;
      font-weight: 400;
    }
  }
  &.ant-input-textarea-show-count::after {
    color: #8c8c8c;
    font-weight: 400;
    font-size: $px10;
    line-height: $px20;
  }
}

.ant-input-textarea-affix-wrapper {
  height: auto !important;
}
.ant-input-textarea-allow-clear > .ant-input {
  padding-inline-end: $px24;
  padding: $px4 $px11;
  border-radius: $px6;
}

.ant-input-number {
  font-size: $px16;
  width: 100%;
  border-radius: $px6;
  border: $px1 solid #d9d9d9;
  .ant-input-number-handler-wrap {
    width: $px30;
    border-start-end-radius: $px6;
    border-end-end-radius: $px6;
    .ant-input-number-handler {
      border-start-end-radius: $px6;
      border-inline-start: $px1 solid #d9d9d9;
    }
    .ant-input-number-handler-up-inner,
    .ant-input-number-handler-down-inner {
      font-size: $px7 !important;
    }
    .ant-input-number-handler-down {
      border-block-start: $px1 solid #d9d9d9;
      font-size: $px7 !important;
    }
  }
  .ant-input-number-input-wrap .ant-input-number-input {
    height: $px32;
    padding: 0 $px12;
    border-radius: $px6;
    font-size: $px16;
  }
}
.ant-input-number-outlined.ant-input-number-status-error:not(.ant-input-number-disabled) {
  border-width: $px1;
}

.ant-input-clear-icon {
  display: none !important;
}

.ant-input-affix-wrapper:hover .ant-input-clear-icon {
  display: flex !important;
}
// end css input

// start css select
.avic-select {
  &.ant-select {
    .ant-select-arrow {
      font-size: $px12;
      inset-inline-end: $px11;
      height: $px12;
      margin-top: -$px6;
    }
    .ant-select-selector {
      position: relative;
      height: $px35;
      background: var(--commonInputBg);
      padding: 0 $px12;
      border-radius: $px12;
      font-size: $px16;
      border: $px1 solid #d9d9d9;
      .ant-select-selection-search-input {
        height: calc(#{$px35} - #{$px1});
      }

      .ant-select-selection-placeholder {
        height: calc(#{$px35} - #{$px1});
        line-height: calc(#{$px35} - #{$px1});
        color: var(--commonInputPlaceholder) !important;
        font-size: $px16 !important;
        font-weight: 400 !important;
        padding-inline-end: $px18;
      }
      .ant-select-item-option-content,
      .ant-select-selection-item {
        color: var(--commonInputColor);
        line-height: $px35;
        font-size: $px16;
        padding-inline-end: $px18;
      }
      .ant-select-selection-search {
        inset-inline-start: $px12;
        inset-inline-end: $px12;
      }
    }
    .ant-select-clear {
      background: var(--commonInputBg);
      width: $px16;
      height: $px16;
      font-size: $px16;
      margin-top: -$px6;
      inset-inline-end: $px12;
    }
    &.ant-select-disabled {
      .ant-select-selector {
        background: var(--commonInputDisable);
      }
      .ant-select-clear {
        background: var(--commonInputDisable);
      }
    }
    &.ant-select-multiple {
      .ant-select-selection-item {
        color: var(--commonInputColor);
        line-height: $px28;
        font-size: $px16;
        margin: $px2 $px4 $px2 0;
        height: $px28;
        margin-inline-end: $px4;
        padding-inline-start: $px8;
        padding-inline-end: $px4;
        border: $px1 solid transparent;
        border-radius: $px4;
      }
      .ant-select-selection-item-remove {
        font-size: $px10;
      }
      .ant-select-selection-item-content {
        margin-inline-end: $px4;
      }
      .ant-select-selection-overflow {
        .ant-tag .ant-tag-close-icon {
          margin-inline-start: $px6;
          font-size: $px12;
        }
      }
      .ant-select-selection-search {
        inset-inline-start: 0;
        inset-inline-end: $px12;
        margin-inline-start: 0;
        width: $px25 !important;
      }
      .ant-select-selector {
        padding-inline-end: $px24;
        padding-inline: $px12;
        padding-block: $px1;
        min-height: $px32;
        height: auto !important;
        &::after {
          margin: $px2 0;
          line-height: $px24;
        }
      }
      .ant-select-selection-placeholder {
        inset-inline-start: $px11;
        inset-inline-end: $px11;
      }
    }
  }
}

.ant-select-outlined.ant-select-status-error:not(.ant-select-customize-input) .ant-select-selector {
  border: $px1 solid #ff4d4f;
  background: #ffffff;
}

.ant-select-item {
  &.ant-select-item-option {
    min-height: $px32;
    padding: $px5 $px12;
    font-size: $px16;
    line-height: 1.5714285714285714;
    border-radius: $px4;
  }
}

.ant-select-dropdown {
  z-index: 9999;
  padding: $px4;
  font-size: $px16;
  border-radius: $px8;
  box-shadow: 0 $px6 $px16 0 rgba(0, 0, 0, 0.08), 0 $px3 $px6 -$px4 rgba(0, 0, 0, 0.12),
    0 $px9 $px28 $px8 rgba(0, 0, 0, 0.05);
}

.ant-select-single {
  font-size: $px16;
  height: $px32;
  color: var(--commonInputColor);
  .ant-select-selection-search-input {
    color: var(--commonInputColor) !important;
  }
}
// end css select

// start css datepicker, rangepicker
.avic-datepicker {
  &.ant-picker {
    width: 100%;
    height: $px35;
    padding: $px4 $px12 $px4;
    border-radius: $px12;
    // border-bottom-right-radius: 0;
    background: var(--commonInputBg);
    &.ant-picker-disabled {
      background: var(--commonInputDisable);
    }
    .ant-picker-input {
      > input {
        font-size: $px16;
        font-weight: 400;
        color: var(--commonInputColor);

        &::placeholder {
          color: var(--commonInputPlaceholder);
          font-size: $px16;
          font-weight: 400;
        }
      }
      svg {
        width: $px14;
        height: $px14;
      }
    }
  }
}

.avic-range-picker {
  &.ant-picker {
    width: 100%;
    height: $px35;
    background: var(--commonInputBg);
    padding: $px4 $px12 $px4;
    border-radius: $px12;
    border-width: $px1;
    font-size: $px16;
    .ant-picker-range-separator {
      padding: 0 $px8;
    }
    .ant-picker-suffix {
      margin-inline-start: $px4;
    }
    .ant-picker-active-bar {
      bottom: -$px1;
      height: $px2;
      margin-inline-start: $px11;
    }
    .ant-picker-input {
      > input {
        font-size: $px16;
        font-weight: 400;
        color: var(--commonInputColor);

        &::placeholder {
          color: var(--commonInputPlaceholder);
          font-size: $px16;
          font-weight: 400;
        }
      }
    }
  }
}

.ant-picker-dropdown-range {
  padding: $px11 0;
  font-size: $px16;
  .ant-picker-range-arrow {
    margin-inline-start: $px16;
    width: $px16;
    height: $px16;
  }
  .ant-picker-panel-container {
    border-radius: $px8;
    box-shadow: 0 $px6 $px16 0 rgba(0, 0, 0, 0.08), 0 $px3 $px6 -$px4 rgba(0, 0, 0, 0.12),
      0 $px9 $px28 $px8 rgba(0, 0, 0, 0.05);
  }
  .ant-picker-panel {
    border: $px1 solid rgba(5, 5, 5, 0.06);
  }
  .ant-picker-date-panel {
    width: $px290;
    .ant-picker-header {
      padding: 0 $px8;
      border-bottom: $px1 solid rgba(5, 5, 5, 0.06);
      button {
        line-height: $px40;
        font-size: $px20;
      }
      .ant-picker-next-icon,
      .ant-picker-super-next-icon,
      .ant-picker-prev-icon,
      .ant-picker-super-prev-icon {
        width: $px7;
        height: $px7;
        &::after,
        &::before {
          width: $px7;
          height: $px7;
          border-block-start-width: $px1;
          border-inline-start-width: $px1;
        }

        &::after {
          top: $px4;
          inset-inline-start: $px4;
        }
      }

      .ant-picker-header-view {
        line-height: $px40;
        button:not(:first-child) {
          margin-inline-start: $px8;
        }
      }
    }
    .ant-picker-body {
      padding: $px8 $px18;
      .ant-picker-content {
        border-spacing: $px2;
        width: $px252;
        th {
          width: $px36;
          height: $px36;
        }
        td {
          min-width: $px24;
          padding: $px6 0;
          &::before {
            height: $px24;
          }
          &::after {
            height: $px24 !important;
            border-top: $px1 dashed #7cb3ff !important;
            border-bottom: $px1 dashed #7cb3ff !important;
          }
          &.ant-picker-cell-range-hover-end::after {
            inset-inline-end: $px6;
            border-inline-end: $px1 dashed #7cb3ff;
            border-start-end-radius: $px4;
            border-end-end-radius: $px4;
            inset-inline-start: $px2;
          }
          &.ant-picker-cell-range-hover-start::after {
            inset-inline-start: $px6;
            border-inline-start: $px1 dashed #7cb3ff;
            border-start-start-radius: $px4;
            border-end-start-radius: $px4;
          }
          &.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(
              .ant-picker-cell-range-end
            )
            .ant-picker-cell-inner {
            border-start-start-radius: $px4;
            border-end-start-radius: $px4;
          }
          &.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(
              .ant-picker-cell-range-start
            )
            .ant-picker-cell-inner {
            border-start-end-radius: $px4;
            border-end-end-radius: $px4;
          }
          .ant-picker-cell-inner {
            min-width: $px24;
            height: $px24;
            line-height: $px24;
            border-radius: $px4;
            &::before {
              border: $px1 solid #1677ff;
              border-radius: $px4;
            }
          }
        }
      }
    }
  }
}
// end css datepicker, rangepicker

///start css button
.avic-btn {
  &.ant-btn {
    outline: none !important;
    height: $px40;
    // line-height: calc(#{$px55} - #{$px2});
    min-width: $px150;
    font-size: $px16;
    font-weight: 700;
    padding: 0 $px20;
    border-radius: $px12;
    // border-bottom-right-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &.ant-btn-sm {
      height: $px40;
      line-height: 100% !important;
      min-width: $px150;
      font-size: $px16;
    }

    &.ant-btn-md {
      height: $px45;
      line-height: 100% !important;
      min-width: $px130;
      font-size: $px15;
    }

    &.ant-btn-lg {
      height: $px52;
      line-height: 100% !important;
      font-size: $px16;
    }

    &.true-width {
      height: $px35;
      line-height: calc(#{$px35} - #{$px2});
      min-width: unset;
    }

    &.ant-btn-primary {
      color: var(--btnDefaultColor);
      background: var(--btnDefaultBg);
      border: $px1 solid var(--btnDefaultBorder);
      box-shadow: 0 0 $px5 rgba(0, 0, 0, 0.25);

      &:disabled {
        opacity: 0.3;
      }
      &.default {
        color: var(--btnDefaultColor);
        background: var(--btnDefaultBg);
        border: $px1 solid var(--btnDefaultBorder);
        &:hover {
          background: var(--btnDefaultHoverBg);
        }
        &:active {
          background: var(--btnDefaultHoverBg);
        }
      }
      &.primary {
        color: var(--btnPrimaryColor);
        background: var(--btnPrimaryBg);
        border: $px1 solid var(--btnPrimaryBorder);
        &:hover {
          background: var(--btnPrimaryHoverBg);
        }
        &:active {
          background: var(--btnPrimaryHoverBg);
        }
      }
      &.info {
        color: var(--btnInfoColor);
        background: var(--btnInfoBg);
        border: $px1 solid var(--btnInfoBorder);
        &:hover {
          background: var(--btnInfoHoverBg);
        }
        &:active {
          background: var(--btnInfoHoverBg);
        }
      }
      &.success {
        color: var(--btnSuccessColor);
        background: var(--btnSuccessBg);
        border: $px1 solid var(--btnSuccessBorder);
        &:hover {
          background: var(--btnSuccessHoverBg);
        }
        &:active {
          background: var(--btnSuccessHoverBg);
        }
      }
      &.danger {
        color: var(--btnDangerColor);
        background: var(--btnDangerBg);
        border: $px1 solid var(--btnDangerBorder);
        &:hover {
          background: var(--btnDangerHoverBg);
        }
        &:active {
          background: var(--btnDangerHoverBg);
        }
      }
      &.warning {
        color: var(--btnWarningColor);
        background: var(--btnWarningBg);
        border: $px1 solid var(--btnWarningBorder);
        &:hover {
          background: var(--btnWarningHoverBg);
        }
        &:active {
          background: var(--btnWarningHoverBg);
        }
      }

      &.ant-btn-background-ghost:not(:hover) {
        color: var(--btnDefaultColor);
        background: transparent;
        border: $px1 solid var(--btnDefaultBorder);
        &.primary {
          color: var(--btnPrimaryBg);
          border: $px1 solid var(--btnPrimaryBorder);
        }
        &.info {
          color: var(--btnInfoBg);
          border: $px1 solid var(--btnInfoBorder);
        }
        &.success {
          color: var(--btnSuccessBg);
          border: $px1 solid var(--btnSuccessBorder);
        }
        &.danger {
          color: var(--btnDangerBg);
          border: $px1 solid var(--btnDangerBorder);
        }
        &.warning {
          color: var(--btnWarningBg);
          border: $px1 solid var(--btnWarningBorder);
        }
      }
    }

    &.btn-icon-left span,
    &.btn-icon-left {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: $px20;
        height: $px20;
        margin-right: $px8;
        path {
          fill: #ffffff;
        }
      }
    }

    &.btn-icon-right {
      display: flex;
      align-items: center;
      svg {
        width: $px20;
        height: $px20;
        margin-left: $px8;
        path {
          fill: #ffffff;
        }
      }
    }
    //&.ant-btn-dangerous{
    //  height: $px40;
    //  min-width: $px100;
    //  font-size: $px16;
    //  justify-content: center;
    //  color: var(--btnPrimaryColor);
    //  background: var(--btnBgDangerous);
    //  &:hover {
    //    background: var(--btnBgDangerousHover);
    //  }
    //  &:active {
    //    background: var(--btnBgDangerousActive);
    //  }
    //  &:disabled {
    //    background: var(--btnBgDangerousDisable);
    //  }
    //}
  }
}
///end css button

// START create, edit, confirm,... modal
.ant-modal {
  font-size: $px16;
  top: $px100;
  min-width: $px520 !important;
  .ant-modal-close {
    top: $px16;
    inset-inline-end: $px16;
    width: $px24;
    height: $px24;

    .ant-modal-close-x {
      font-size: $px16;
      font-style: normal;
      line-height: $px22;
    }
  }
  .ant-modal-content {
    padding: $px20 $px24;
    border-radius: $px8;
    box-shadow: 0 $px6 $px16 0 rgba(0, 0, 0, 0.08), 0 $px3 $px6 -$px4 rgba(0, 0, 0, 0.12),
      0 $px9 $px28 $px8 rgba(0, 0, 0, 0.05);
  }
  .ant-modal-header {
    position: relative;
    border-radius: $px8 $px8 0 0;
    margin-bottom: $px8;
    .ant-modal-title {
      color: #2563eb;
      font-weight: 700;
      font-size: $px20;
      line-height: $px24;
      display: flex;
      justify-content: center;
      padding: $px16 0;
    }
    &::before {
      content: " ";
      display: block;
      width: 50%;
      height: $px2;
      background: #c4c4c4;
      border-radius: $px3;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%);
    }
  }
  .ant-modal-footer {
    display: flex;
    justify-content: center;
    margin-top: $px12;
    padding: $px8;
    .ant-btn {
      min-width: $px150;
      height: $px40;
      display: flex;
      align-items: center;
      justify-content: center;
      & + .ant-btn {
        margin-inline-start: $px8;
      }
    }
  }
  &.avic-modal {
    .list-title {
      font-weight: 700;
      font-size: $px16;
      line-height: $px18;
      color: #464646;
      margin-top: $px8;
    }

    .list-total {
      font-style: italic;
      font-weight: 400;
      font-size: $px16;
      line-height: $px22;
      color: #8c8c8c;
      margin: $px8 0 $px8 $px16;
    }

    // .ant-table {
    //   .ant-table-thead > tr > th,
    //   .ant-table-thead > tr > td {
    //     background: #f3f4f6;
    //     color: #000000;
    //   }
    // }
  }
}

.title-detail {
  font-weight: 700;
  font-size: $px30;
  color: #000000;
  margin-bottom: $px10;
}

.footer-detail {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: $px32;
}

.confirm-modal {
  width: $px425 !important;
  z-index: 1000;
  .ant-modal-footer {
    border-top: none;
    text-align: center;
    justify-content: center;
    padding: $px8;
  }
  .ant-modal-body {
    text-align: center;
  }
  .ant-btn {
    height: $px36 !important;
    min-width: $px70 !important;
    background: #ffffff;
    box-shadow: 0px $px1 $px2 rgba(31, 41, 55, 0.08);
    border-radius: $px4;
    font-size: $px16;
    padding: $px4 $px16;
  }
  .confirm-modal-btn-close {
    border: $px1 solid #1e40af !important;
    background-color: #ffffff;
    color: #1e40af !important;
    &:hover {
      background-color: rgba(239, 237, 237, 0.8);
    }
  }
  .confirm-modal-btn-ok {
    background-color: #1e40af;
    border: $px1 solid #1e40af;
    &:hover {
      background-color: rgba(30, 64, 175, 0.8) !important;
    }
  }
  .confirm-modal-title {
    font-weight: 700;
    font-size: $px24;
    line-height: $px28;
    color: #1e3a8a;
    margin-top: $px8;
  }
  .confirm-modal-svg {
    width: $px64;
    height: auto;
    margin-top: $px18;
  }
  .confirm-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: $px16 auto 0;
    font-weight: 600;
    font-size: $px16 !important;
    line-height: $px20;

    .ant-col {
      font-size: $px20;
    }
  }
}
// END create, edit, confirm,... modal

// start css table
.avic-table-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $px10;

  .avic-table-top-title {
    font-size: $px24;
    line-height: $px28;
    font-weight: 600;
    color: var(--commonInputColor);
  }

  .ant-space-item {
    display: flex;
  }
}

.avic-table {
  &.ant-table-wrapper {
    .ant-table-content,
    .ant-table-header,
    .ant-table.ant-table-empty {
      border-radius: $px8 $px8 0 0 !important;
    }
    .ant-table-selection-column {
      width: 4% !important;
      padding-inline-end: $px8;
      padding-inline-start: $px8;
    }

    .ant-table-thead {
      > tr {
        > td,
        > th {
          background: var(--btnPrimaryBg);
          color: var(--sidebarContentTextColor1);
          padding: $px16 $px16;
          font-size: $px16;
          border-bottom: $px1 solid #f0f0f0;
          &.ant-table-column-has-sorters {
            &:hover {
              background: var(--btnPrimaryBg);
              opacity: 0.95;
              color: white;
              &:before {
                background-color: #f0f0f0 !important;
              }
            }

            .ant-table-column-sorter {
              color: var(--sidebarContentTextColor3);
              .active {
                color: var(--sidebarContentTextColor1);
                &:before {
                  background-color: #f0f0f0 !important;
                }
              }
            }
          }
          &::before {
            width: $px1 !important;
            height: 50% !important;
          }
          &.ant-table-selection-column {
            &::before {
              position: absolute;
              top: 50%;
              inset-inline-end: 0;
              width: $px1;
              height: 50%;
              background-color: #f0f0f0;
              transform: translateY(-50%);
              transition: background-color 0.2s;
              content: "";
            }
          }
        }
      }
    }
    .ant-table-tbody {
      > tr {
        &.ant-table-row {
          > td {
            padding: $px16 $px16;
            font-size: $px16;
            border-bottom: $px1 solid #f0f0f0;
            &:first-child {
              border-radius: 0 !important;
            }
            &:last-child {
              border-radius: 0 !important;
            }
          }
        }
        &.ant-table-placeholder {
          .ant-table-cell {
            border-bottom: $px1 solid #f0f0f0;
            padding: $px16 $px16;
            .ant-empty-normal {
              margin-block: $px32;
              margin-inline: $px8;
              font-size: $px16;
            }
            .ant-empty-image {
              height: $px40 !important;
            }
          }
        }
      }
    }
    .ant-pagination {
      font-size: $px16;
      margin: $px16 0;
      .ant-pagination-item {
        margin: 0 $px10;
        border-radius: $px8;
        border: $px1 solid transparent;
        min-width: $px32;
        height: $px32;
        margin-inline-end: $px8;
        line-height: $px32;
        &:focus-visible {
          outline: $px4 solid #91caff;
          outline-offset: $px1;
        }

        a {
          padding: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:not(.ant-pagination-item-active) {
          background-color: #f5f5f5;
        }

        &:hover,
        &-active {
          background-color: var(--btnPrimaryBg) !important;
          border-color: var(--btnPrimaryBorder) !important;
          a {
            color: #ffffff;
          }
        }
      }

      .ant-pagination-jump-prev,
      .ant-pagination-jump-next,
      .ant-pagination-item-link,
      .ant-pagination-prev,
      .ant-pagination-next {
        background-color: #f5f5f5;
        border-radius: $px8;
        font-size: $px16;
        min-width: $px32;
        height: $px32;
        line-height: $px32;
        border: $px1 solid transparent;
      }

      .ant-pagination-next {
        margin-right: $px16;
      }

      .ant-pagination-prev {
        margin-left: $px12;
        margin-inline-end: $px8;
      }

      .ant-pagination-total-text {
        height: $px32;
        line-height: $px32;
      }

      .ant-pagination-options {
        margin-inline-start: $px2;
        .ant-select-single.ant-select-sm {
          font-size: $px16;
          height: $px32;
        }
        .ant-select-selector {
          padding: 0 $px7;
          border-radius: $px4;
          font-size: $px16;
          height: $px32;
          border: $px1 solid #d9d9d9;
          .ant-select-selection-item {
            padding-inline-end: $px20;
            line-height: $px32;
          }
          .ant-select-selection-search {
            inset-inline-end: $px28;
            inset-inline-start: $px7;
            .ant-select-selection-search-input {
              height: $px32;
            }
          }
        }
        .ant-select-arrow {
          inset-inline-end: $px12;
          height: $px12;
          margin-top: -$px6;
          font-size: $px12;
        }
        .ant-select-dropdown {
          border-radius: $px8;
          font-size: $px16;
          padding: $px4;
          .ant-select-item-option {
            min-height: $px32;
            padding: $px5 $px12;
            font-size: $px16;
            border-radius: $px4;
          }
        }
      }

      .ant-pagination-jump-prev,
      .ant-pagination-jump-next {
        &:focus-visible {
          outline: $px4 solid #91caff;
          outline-offset: $px1;
        }
        .ant-pagination-item-link {
          border: unset;
          .ant-pagination-item-container {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .ant-pagination-item-link-icon {
              font-size: $px16;
            }

            .ant-pagination-item-ellipsis {
              letter-spacing: $px2;
              text-indent: $px2;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    .ant-table-row-expand-icon {
      margin-top: $px2;
      margin-inline-end: $px8;
      width: $px17;
      height: $px17;
      line-height: $px17;
      border: $px1 solid #d9d9d9;
      border-radius: 0;
      &::before {
        top: $px7;
        inset-inline-end: $px3;
        inset-inline-start: $px3;
        height: $px1;
      }
      &::after {
        top: $px3;
        bottom: $px3;
        inset-inline-start: $px7;
        width: $px1;
      }
    }
    .ant-table-column-sorter {
      margin-inline-start: $px4;
      .anticon {
        &.anticon-caret-up,
        &.anticon-caret-down {
          font-size: $px12;
        }
      }
    }
    .ant-table-filter-trigger {
      margin-block: -$px4;
      margin-inline: $px4 -$px8;
      padding: 0 $px4;
      font-size: $px16;
      border-radius: $px6;
      margin-left: $px2;
    }
    .ant-table-container table > thead > tr:first-child {
      > *:first-child {
        border-start-start-radius: $px8;
      }
      > *:last-child {
        border-start-end-radius: $px8;
      }
    }
  }

  .anticon.anticon-search > svg {
    fill: #ffffff;
  }
}

.ant-table-filter-dropdown {
  > div {
    padding: $px8;
    font-size: $px16;
    .ant-input {
      display: block;
      margin-bottom: $px8;
      padding: $px4 $px11;
      font-size: $px16;
      border-width: $px1;
      border-radius: $px6;
    }
    > .ant-space {
      gap: $px8;
    }
  }
}
/* table sort*/
.ant-table {
  .row-table {
    display: flex;
    justify-content: space-around;
    .ant-select:first-child {
      width: 30%;
    }
    .ant-select:last-child {
      width: 80%;
    }
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      text-align: left;
    }
    &.dangerous {
      .ant-select-selector {
        border: $px1 solid red;
      }
    }
  }
}

.delete-icon {
  width: $px24;
  height: $px24;
  cursor: pointer;
}

.add-icon {
  width: $px36;
  height: $px36;
  cursor: pointer;
}
/* table sort*/
// end css table

.avic-vertical-step {
  &.ant-steps {
    .ant-steps-item-title {
      font-size: $px16;
      font-weight: 700;
    }
    .ant-steps-item-description {
      font-size: $px16;
    }
  }
}

.react-dropzone {
  .react-dropzone-thumb {
    margin-top: $px20;
    position: relative;
    border-radius: $px4;
    border: $px1 solid #eaeaea;
    padding: $px4;
    .react-dropzone-thumb-img {
      width: 100%;
    }
    .react-dropzone-thumb-btn {
      position: absolute;
      left: $px10;
      top: $px10;
    }
  }
}

.avatar-box-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $px15 0 $px15 0;
  .avatar-box {
    position: relative;
    .avatar {
      background: #f3f4f6;
      color: #d1d5db;
      width: $px175;
      height: $px175;
      font-size: $px90;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .avatar-change-icon {
      position: absolute;
      bottom: $px10;
      right: $px10;
      width: $px35;
      height: $px35;
      border-radius: 50%;
      border: $px4 solid #fff;
      background: #2f80ed;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .ant-upload-wrapper {
        line-height: unset;
        font-size: $px20;
      }

      svg {
        width: $px18;
        height: $px16;
        path {
          fill: #fff;
        }
      }
    }
  }
}

.avic-label {
  font-size: $px16;
  font-weight: 500;
  line-height: $px28;
}

// start css tag
.avic-tag {
  &.ant-tag {
    font-size: $px14;
    line-height: $px24;
    padding-inline: $px10;
    color: #818181;
    border: $px1 solid #e3d4d4;
    border-radius: $px3;
    background: #ffffff;
    &.primary {
      color: var(--btnPrimaryBg);
      border: $px1 solid var(--btnPrimaryBorder);
      .ant-tag-close-icon {
        color: var(--btnPrimaryBg);
      }
    }
    &.info {
      color: var(--btnInfoBg);
      border: $px1 solid var(--btnInfoBorder);
      .ant-tag-close-icon {
        color: var(--btnInfoBg);
      }
    }
    &.success {
      color: var(--btnSuccessBg);
      border: $px1 solid var(--btnSuccessBorder);
      .ant-tag-close-icon {
        color: var(--btnSuccessBg);
      }
    }
    &.danger {
      color: var(--btnDangerBg);
      border: $px1 solid var(--btnDangerBorder);
      .ant-tag-close-icon {
        color: var(--btnDangerBg);
      }
    }
    &.warning {
      color: var(--btnWarningBg);
      border: $px1 solid var(--btnWarningBorder);
      .ant-tag-close-icon {
        color: var(--btnWarningBg);
      }
    }
  }
}
// end css tag

// start overwrite css drop down
.ant-dropdown {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      font-size: $px16;
      padding: $px5 $px12;
      border-radius: $px4;
      outline: none !important;
    }
    .ant-dropdown-menu-submenu {
      .ant-dropdown-menu-submenu-title {
        font-size: $px16;
        padding: $px4 $px12;
        display: flex;
        .ant-dropdown-menu-submenu-expand-icon {
          .ant-dropdown-menu-submenu-arrow-icon {
            font-size: $px12;
          }
        }
      }
    }
  }
}
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item {
  font-size: $px16;
  padding: $px4 $px12;
}
.ant-dropdown-menu-submenu .ant-dropdown-menu {
  padding: $px4;
  border-radius: $px8;
}
.ant-dropdown .ant-dropdown-menu {
  padding: $px4;
  border-radius: $px8;
}
// end overwrite css drop down

// start overwrite css breadcrumb
.ant-breadcrumb {
  font-size: $px16;

  ol {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  a {
    font-size: $px16;
    height: 100%;
    padding: 0 $px4;
    border-radius: $px4;
    margin-inline: -$px4;
  }

  &.avic-breadcrumb {
    padding: $px16;
  }

  .ant-breadcrumb-separator {
    margin-inline: $px12;
    display: flex;
    align-items: center;
    svg {
      width: auto;
      height: $px20;
    }
  }
}
// end overwrite css breadcrumb

// start css ant-tree
.avic-tree {
  &.ant-tree {
    font-size: $px16;
    .ant-tree-checkbox {
      font-size: $px16;
      margin-inline-end: $px8;
      margin-top: $px6;
      border-radius: $px4;
      .ant-tree-checkbox-inner {
        width: $px16;
        height: $px16;
        border: $px1 solid #d9d9d9;
        border-radius: $px4;
        &::after {
          width: calc(#{$px8} - 0.085vw);
          height: calc(#{$px10} - 0.007vw);
          border: $px4 solid #fff;
          border-top: 0;
          border-inline-start: 0;
        }
      }
      &.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
        border: 0;
        border-top: $px1 solid #fff;
        border-bottom: $px1 solid #fff;
      }
    }

    .ant-tree-checkbox-disabled {
      &.ant-tree-checkbox-indeterminate {
        .ant-tree-checkbox-inner::after {
          background-color: #1677ff;
        }
      }
      .ant-tree-checkbox-inner::after {
        border-color: #1677ff;
      }
    }

    .ant-tree-node-content-wrapper {
      min-height: $px24;
      padding: 0 $px4;
      line-height: $px24;
      border-radius: $px6;
      color: var(--commonInputColor);
    }

    .ant-tree-treenode {
      padding: 0 0 $px4 0 !important;
      .ant-tree-switcher {
        width: $px24;
        line-height: $px24;
        margin-top: $px3;
        .ant-tree-switcher-leaf-line::before {
          inset-inline-end: $px16;
          bottom: -$px4;
          margin-inline-start: -$px1;
          border-inline-end: $px1 solid #d9d9d9;
          height: $px30 !important;
        }
        .ant-tree-switcher-leaf-line::after {
          margin-left: -$px4;
          width: $px10;
          height: $px12;
          border-bottom: $px1 solid #d9d9d9;
        }
      }
    }

    .ant-tree-indent-unit {
      width: $px20;
      &::before {
        inset-inline-end: $px12;
        bottom: -$px4;
        border-inline-end: $px1 solid #d9d9d9;
      }
    }
  }
}
// end css ant-tree

.todolist-index {
  width: $px30;
  height: $px30;
  line-height: $px30;
  font-size: $px18;
}

// START CSS PAGINATION
.avic-pagination {
  text-align: center;
  .ant-pagination-jump-prev,
  .ant-pagination-prev,
  .ant-pagination-jump-next,
  .ant-pagination-next,
  .ant-pagination-item {
    background-color: #f5f5f5;
    font-weight: 400;
    font-size: $px16;
    line-height: $px28;
    color: #595959;
    min-width: $px32;
    height: $px32;
    border-radius: $px6;
    margin-inline-end: $px4;
    margin-top: $px8;
    border: $px1 solid transparent;
    a {
      padding: 0 $px6;
    }
    .anticon svg {
      height: $px12;
      width: $px12;
    }
    button.ant-pagination-item-link {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .ant-pagination-item.ant-pagination-item-active,
  .ant-pagination-item:not(.ant-pagination-item-active):hover {
    background-color: #1890ff;
    a {
      color: #ffffff;
    }
  }
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    letter-spacing: $px2;
    text-indent: $px2;
  }
  .ant-pagination-options {
    margin-inline-start: $px2;
    .ant-select-single.ant-select-sm {
      font-size: $px16;
      height: $px36;
    }
    .ant-select-selector {
      padding: 0 $px7;
      border-radius: $px4;
      font-size: $px16;
      height: $px36;
      border: $px1 solid #d9d9d9;
      .ant-select-selection-item {
        padding-inline-end: $px20;
        line-height: $px36;
      }
      .ant-select-selection-search {
        inset-inline-end: $px28;
        inset-inline-start: $px7;
        .ant-select-selection-search-input {
          height: $px36;
        }
      }
    }
    .ant-select-arrow {
      inset-inline-end: $px12;
      height: $px12;
      margin-top: -$px6;
      font-size: $px12;
    }
    .ant-select-dropdown {
      border-radius: $px8;
      font-size: $px16;
      padding: $px4;
      .ant-select-item-option {
        min-height: $px32;
        padding: $px5 $px12;
        font-size: $px16;
        border-radius: $px4;
      }
    }
  }
}
// END CSS PAGINATION

// START CSS EMPTY
.ant-empty {
  .ant-empty-description {
    font-size: $px20;
  }
  .ant-empty-image {
    height: 80% !important;
    margin-bottom: $px8;
    display: flex;

    svg {
      width: auto;
    }
  }
  &.avic-empty {
    .ant-empty-image {
      height: $px142;
      svg {
        width: $px175;
      }
    }
    .ant-empty-description {
      font-weight: 700;
      font-size: $px16;
      line-height: $px26;
      color: #a1a1aa;
      margin-top: $px16;
    }
  }
}
// END CSS EMPTY

// START CSS DRAWER
// Drawer
.avic-drawer {
  .ant-drawer-header {
    display: flex;
    flex: 0;
    align-items: center;
    padding: $px16 $px24;
    font-size: $px16;
    line-height: 1.5;
    border-bottom: $px1 solid rgba(5, 5, 5, 0.06);
    .ant-drawer-title {
      color: var(--btnPrimaryBg);
      font-weight: 500;
      font-size: $px24;
      .drawer-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg {
          cursor: pointer;
          width: $px40;
          height: $px40;
        }
      }
    }
  }

  .ant-drawer-body {
    padding: 0;
  }

  .drawer-section {
    font-weight: 500;
    font-size: $px18;
    color: black;
    width: 100%;
  }

  .ant-divider-dashed {
    color: black;
  }

  .ant-drawer-mask {
    background-color: transparent;
  }

  .ant-drawer-footer {
    background-color: #f8fafc;
    height: $px110;
    .row-drawer {
      background-color: #f8fafc;
      width: 100%;
      height: $px110;
      padding-right: $px8;
      position: absolute;
      bottom: 0;
      left: 0;
      border-top: $px1 dashed #e2e8f0;
      z-index: 10;
      justify-content: flex-end;
      .avic-btn {
        height: $px55;
        text-transform: uppercase;
      }
    }
  }
}

.detail-page-box {
  padding: 0 $px20;
  background: #fff;
  margin-bottom: $px35;
}
.box-title {
  font-size: $px18;
  color: var(--commonInputColor);
  font-weight: 600;
  margin: $px15 0;
}
// END CSS DRAWER

.ant-result-extra {
  display: flex;
  justify-content: center;
}

// css check box start
.ant-checkbox-wrapper {
  font-size: $px16 !important;
  .ant-checkbox {
    &::after {
      border-radius: $px4;
      border: $px2 solid #1677ff;
      font-size: $px16 !important;
    }
    &.ant-checkbox-indeterminate .ant-checkbox-inner:after {
      width: $px8;
      height: $px8;
      border: 0;
    }
    &.ant-checkbox-disabled .ant-checkbox-inner::after {
      border-color: #1677ff;
    }

    .ant-checkbox-inner {
      width: $px20;
      height: $px20;
      border-radius: $px4;
      border: $px1 solid #d9d9d9;
      outline: none;
      &::after {
        width: calc(#{$px9} - 0.085vw);
        height: calc(#{$px11} - 0.007vw);
        border: $px4 solid #fff;
        border-top: 0;
        border-inline-start: 0;
      }
    }
    .ant-checkbox-input {
      width: $px20 !important;
      height: $px20 !important;
    }
    & + span {
      padding-inline-start: $px8;
      padding-inline-end: $px8;
      color: #262626 !important;
    }
  }
}
// css checkbox end

// start css ant-col
.ant-col {
  &.ant-form-item-label {
    display: flex;
    label {
      flex: 1;
    }
  }
  .ant-form-item-control-input-content {
    display: flex;
  }
}
// end css ant-col

// start css spin
.ant-spin {
  max-height: $px400;
  .ant-spin-dot {
    font-size: $px40 !important;
    margin: -$px10 !important;
  }
}
// end css spin

// start css ant-result
.ant-result {
  padding: $px48 $px32;
  font-size: $px16;
  .ant-result-icon {
    margin-bottom: $px24;
    width: $px252;
    height: $px300;
    margin: auto;
  }
  .ant-result-title {
    font-size: $px48;
    margin-block: $px8;
  }
  .ant-result-subtitle {
    font-size: $px32;
  }
  .ant-result-extra {
    margin: $px24 0 0 0;
  }
  .ant-btn {
    transform: scale(1.5);
  }
}
// end css ant-result

// start css ant-tour
.ant-tour {
  font-size: $px16;
  .ant-tour-inner {
    border-radius: $px8;
    box-shadow: 0 $px1 $px2 0 rgba(0, 0, 0, 0.03), 0 $px1 $px6 -$px1 rgba(0, 0, 0, 0.02),
      0 $px2 $px4 0 rgba(0, 0, 0, 0.02);
    .ant-tour-close {
      top: $px16;
      inset-inline-end: $px16;
      width: $px22;
      height: $px22;
      border-radius: $px4;
      font-size: $px20;
    }
    .ant-tour-cover {
      padding: $px50 $px16 0;
    }
    .ant-tour-header {
      padding: $px16 $px16 $px8;
      .ant-tour-title {
        font-size: $px18 !important;
      }
    }
    .ant-tour-description {
      padding: 0 $px16;
    }
    .ant-tour-footer {
      align-items: center;
      .ant-tour-indicators {
        .ant-tour-indicator {
          &:not(:last-child) {
            margin-inline-end: $px10;
          }
          width: $px10;
          height: $px10;
        }
      }
      .ant-tour-buttons {
        .ant-btn {
          outline: none !important;
          height: $px36;
          min-width: $px110;
          font-size: $px16;
          font-weight: 500;
          padding: 0 $px20;
          border-radius: $px12;
        }
      }
    }
  }
}
// end css ant-tour

// start css notify
.ant-notification {
  top: $px32 !important;
  &.ant-notification-topRight {
    margin-right: $px32 !important;
  }
  .ant-notification-notice-wrapper {
    margin-bottom: $px16 !important;
    border-radius: $px8 !important;
    box-shadow: 0 $px6 $px16 0 rgba(0, 0, 0, 0.08), 0 $px3 $px6 -$px4 rgba(0, 0, 0, 0.12),
      0 $px9 $px28 $px8 rgba(0, 0, 0, 0.05) !important;
    font-size: $px18 !important;

    .ant-notification-notice {
      padding: $px20 $px24 !important;
      width: $px520 !important;
      max-width: calc(100vw - #{$px48}) !important;
    }

    .ant-notification-notice-icon {
      font-size: $px32 !important;
      top: 50%;
      transform: translateY(-55%);
    }

    .ant-notification-notice-message {
      margin-bottom: $px8 !important;
      margin-inline-start: $px48 !important;
      font-size: $px24 !important;
      padding-inline-end: $px24 !important;
    }

    .ant-notification-notice-close {
      top: $px20 !important;
      inset-inline-end: $px24 !important;
      width: $px22 !important;
      height: $px22 !important;
      border-radius: $px4 !important;
    }
  }
}
.ant-notification-stack.ant-notification-stack-expanded > .ant-notification-notice-wrapper:after {
  height: $px20 !important;
  bottom: -$px20 !important;
}
// end css notify
