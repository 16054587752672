.account-title {
  font-size: $px24;
  font-weight: bold;
  margin: 0 0 $px16 $px16;
}

.account-sub-title {
  margin: $px8 0;
  color: var(--commonInputColor);
  font-size: $px18;
  font-weight: 500;
}

.account-wrapper {
  display: flex;
  flex-direction: column;
  .account-info {
    padding: 0 $px16;
    display: flex;
    justify-content: space-between;
    .account-left-info {
      width: 30%;
    }
    .account-right-info {
      margin-left: $px16;
      flex: 1;
    }
  }
  .form-btn-container {
    margin-bottom: $px16;
    justify-content: center;
  }
}
