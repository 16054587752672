.main-layout {
  height: 100vh;
  background-color: rgb(238, 238, 238);
  > .ant-layout-content {
    overflow: auto;
    height: 100%;
    .content-wrapper {
      padding: 0 $px8 $px8;
    }
  }

  .ant-layout-header {
    background: transparent;
    height: $px60;
    padding: 0;
    .main-header {
      background: var(--bgColorHeader);
      box-shadow: 0 0 $px15 rgba(0, 0, 0, 0.1);
      line-height: 0;
      display: flex;
      height: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 0 $px15;

      .main-header-left {
        margin-left: -$px20;
        // margin-left: 0;
        display: flex;

        .page-logo {
          height: 100%;
          width: auto;
          margin-right: $px20;
          margin-left: $px20;
          svg {
            height: $px80;
            // height: $px55;
            width: auto;
          }
        }
        .page-title {
          color: var(--sidebarContentTextColor1);
          font-size: $px22;
          font-weight: 600;
          display: flex;
          align-items: center;
          padding-top: $px4;
        }
      }
      .main-header-user {
        color: var(--sidebarContentTextColor1);
        display: flex;
        flex-direction: column;
        text-align: right;

        .main-header-user-name {
          font-size: $px16;
          font-weight: 600;
          line-height: $px20;
        }
        .main-header-user-position {
          font-size: $px14;
          font-weight: 100;
          line-height: $px20;
        }
      }
    }
  }

  .content-box {
    padding: 0 $px16 $px16;
    font-size: $px18;
  }
}
